$fuchsia: #ff0096; // $info
$sp-primary: #7349dd; // $primary
$blue: #00cfe8; 
$red: #e74c3c; //$danger
$orange: #ff9c01; //$warning
$green: #28c76f; //$success
$cyan: #7367f0; 

$primary: $sp-primary;
$secondary: #676a6c;
$info: $fuchsia;
$warning: $orange;
$dark: #011627;
$light: #babfc7;

$purpleish: #5e5873;
$purpleish-grey: #6e6b7b;

// 'SCROLL TO TOP' BUTTON
.Shippypro_ScrollUpButton__Container {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.26);
  transition: all 0.3s ease 0s !important;

  svg {
    width: 30px !important;
    height: 30px !important;
  }

  &:hover {
    transform: translateY(-7px);
  }

  &:focus {
    outline: none;
  }
}

.v-align{ display: flex; align-items: center; }

.option-icon {
  margin-right: 6px;
}

.full-size {
  min-height: calc(100vh)!important;
}

.flatpickr-wrapper .flatpickr-calendar {
  bottom: calc(100% + 5px)!important;
  top: auto!important;
  right: -30%!important;
  &::after, &::before{
    border-top-color: $primary;
    top: 100%;
    left: auto;
    right: 22px;
  }
}

.header-navbar .navbar-container ul.nav {
  li.dropdown .dropdown-menu {
    margin-top: 0;
    left: -12px!important;
    top: 8px!important;
    .dropleft .dropdown-menu::before{
      left: auto;
    }
  }
}

.df .card {
  width: 100%;
}

.vx-checkbox-con {
  margin: 0 0 0 7px;
}

#card-teams {
  header{
    .react-ripples .btn-flat-danger{
      border: 1px solid transparent;
    }
  }
}

.vx-checkbox-md {
  min-width: 20px !important;
  min-height: 20px !important;
}

.vx-checkbox-con span {
  font-size: 12px;
  font-weight: 500;
}

.formik-input-icon {
  top: 21px !important;
  cursor: pointer;
  svg {
    width: 20px !important;
    height: 20px !important;
    margin-top: 9px;
  }
}

.react-contexify {
  z-index: 999;
}

.table-nickname-text {
  color: #b9b9c3;
  font-size: 12px;
  font-weight: initial;
  font-family: 'Poppins-Regular', 'Poppins';
  letter-spacing: 0.2px;
}

.modal-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.modal-body-title {
  font-size: 12px;
  font-weight: normal;
  color:$purpleish;
  letter-spacing: 1px;
  margin: 0 !important;
  margin-bottom: 14px !important;
  height: unset;
}

.form-input-label {
  margin-bottom: 5px;
  font-size: 12px;
  color:$purpleish;
}

.page-drawer-content {
  .form-control {
    color:$purpleish-grey;
    font-size: 14px;
    line-height: 1.5;
  }
  .select__single-value {
    color:$purpleish-grey !important;
    font-size: 14px !important;
    line-height: 1.5;
  }
}

input[type="password"] {
  &.is-invalid {
    background-image: unset !important;
  }
}

.table-checkbox .vx-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 3px;
}

.wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
}

.select__control--is-disabled {
  background-color: #f5f5f1 !important;
}

.page-drawer {
  .page-drawer-header.dangerous svg {
    cursor: pointer;
  }
}

.mt-70 {
  margin-top: 70px !important;
}

.list-view-item-cell:not(.ai-center, .cell-actions) {
  margin-left: 3rem;
}

// .app-content {
//   height: 100%
// }

@import './mobile.scss';
@import './dark-layout.scss';