@media(max-width: 500px){
  .header-dropdown-filters-menu{
    transform: translate3d(-225px, 47px, 0px)!important;
  }

  .flatpickr-wrapper .flatpickr-calendar{
    right: -48%!important;
    &::before, &::after{
      display: none;
    }
  }

  .header-navbar {
    .dropdown-language .dropdown-menu {
      left: 0!important;
    }

    #navbar-mobile.navbar-collapse .navbar-nav .dropdown-user .dropdown-menu {
      left: 0!important;
      width: 100%;
      overflow: visible;
      .dropdown-menu {
        transform: unset!important;
        top: 40px!important;
      }
    }
  }

  .dropleft .dropdown-toggle svg{
    left: 0;
  }

  .modal-wrapper {
    width: calc(100% - 1rem);
    border-radius: 6px;
  }

}

@media(max-width: 767px){

  .header-navbar {
    max-height: 62px;
    .xw-auto {
      margin-top: 4px;
    }
  
    .navbar-nav-user {
      margin-top: 4px !important;
    }
  }

  .header-navbar .navbar-container ul.nav {
    li.dropdown .dropdown-menu {
      left: 15px!important;
      top: 85px!important;
      position: fixed!important;
      transform: unset!important;
      width: calc(100% - 30px);
    }
  }

  .page-drawer {
    padding-bottom: 45px;
  }
}

@media(max-width: 600px){
  .header-button-left, div.position-relative.has-icon-left.mb-1.ml-1.w50 {
    width: 50% !important;
  }
}

@media(max-width: 1199px){
  .header-button-left, div.position-relative.has-icon-left.mb-1.ml-1.w50 {
    width: 30%;
  }

  .header-navbar {
    .navbar-container {
      max-height: 62px;
    }
  
    .navbar-collapse {
      margin-top: -7px;
    }
  
    .navbar-nav-user {
      margin-right: -3px;
    }
  
    .dropdown-language {
      margin-right: -4px;
      margin-top: -1px;
    }
  
    .user-status, .user-name {
      margin-top: -1px;
    }
  }

  .avatar-column {
    display: inline-block !important;
    white-space: nowrap;

    .avatar {
      display: inline-block;
    }
    .user-info {
      display: inline-block;
      vertical-align: middle;
      width: 100px;
    }
  }
}